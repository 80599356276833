// ProcessorFormAIGenerator.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner, Box, Heading, Text, Center, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { updateForm } from '../../utils/firebase.utils';
import { useTranslation } from 'react-i18next';

const ProcessorFormAIGenerator = ({user, accountId}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { runID, threadID } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const checkStatus = async () => {
      setIsLoading(true);
      try {
        const statusResponse = await axios.get('https://eduscope-cfda5.web.app/api/checkStatus', {
          params: { runID, threadID },
        });

        if (statusResponse.data && statusResponse.data.data) {
          let formData;
          try {
            formData = statusResponse.data.data[0].content[0].text.value.replace(/^[\n\`]+|[\n\`]+$/g, '');
            console.log('formData success from AI', formData   );
            formData = JSON.parse(formData);
            formData.createdBy = user.uid;

          } catch (error) {
            console.error('Error parsing AI data:', error);
            throw new Error('Failed to parse AI-generated data.');
          }

          // Save form details to Firestore
          const savedFormId = await updateForm(accountId, formData, threadID);
          navigate(`/admin/assessments_builder/${savedFormId}`);
        } else {
          setTimeout(checkStatus, 5000); // Poll every 5 seconds for updates
        }
      } catch (error) {
        setIsLoading(false);
        console.log('error import form',error);
        toast({
          title: 'Error Generating Assessment',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (!runID || !threadID) {
      toast({
        title: 'Error',
        description: 'Missing required parameters to process AI generation.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      checkStatus();
    }
  }, [runID, threadID, toast, navigate]);

  return (
    <Center flexDirection="column" mt="20">
    <Spinner size="xl" thickness="4px" mb={6} />
    <Heading mb={2}>{t('processingAssessment')}</Heading>
    <Text color={'gray.500'}>{t('processingInfo')}</Text>
  </Center>
  );
};

export default ProcessorFormAIGenerator;