import React, { useState, useEffect, useRef } from 'react';
import {
  VStack,
  Button,
  Box,
  useColorModeValue,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { ChevronUpIcon, ChevronDownIcon, CopyIcon } from '@chakra-ui/icons';
import FormQuestion from './FormQuestion';
import { useTranslation } from 'react-i18next';
import { updateQuestionIndexes } from '../../utils/firebase.utils';

const Questions = ({ formDetails, handleFormChange, addNewQuestion }) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState(formDetails.questions);
  const [openQuestions, setOpenQuestions] = useState({});
  const [isDuplicateOpen, setIsDuplicateOpen] = useState(false);
  const [questionToDuplicate, setQuestionToDuplicate] = useState(null);
  const cancelRef = useRef();

  useEffect(() => {
    checkAndSetIndexes();
  }, [formDetails.questions]);

  const checkAndSetIndexes = async () => {
    const updatedQuestions = { ...formDetails.questions };
    let needsUpdate = false;

    Object.entries(updatedQuestions).forEach(([key, question], index) => {
      if (question.index === undefined || question.index === null) {
        updatedQuestions[key].index = index;
        needsUpdate = true;
      }
    });

    if (needsUpdate) {
      setQuestions(updatedQuestions);
      handleFormChange('questions', updatedQuestions);
      await updateQuestionIndexes(updatedQuestions);
    }
  };

  const moveQuestion = (questionId, direction) => {
    const newQuestions = { ...formDetails.questions };
    const questionEntries = Object.entries(newQuestions).sort(([, a], [, b]) => a.index - b.index);
    const questionIndex = questionEntries.findIndex(([key, question]) => question.id === questionId);
    const targetIndex = questionIndex + direction;

    if (targetIndex >= 0 && targetIndex < questionEntries.length) {
      const temp = questionEntries[questionIndex];
      questionEntries[questionIndex] = questionEntries[targetIndex];
      questionEntries[targetIndex] = temp;

      const reorderedQuestions = {};
      questionEntries.forEach(([key, question], index) => {
        reorderedQuestions[key] = { ...question, index };
      });

      setQuestions(reorderedQuestions);
      handleFormChange('questions', reorderedQuestions);

      setOpenQuestions(prevState => {
        const newState = { ...prevState };
        delete newState[questionId];
        newState[questionEntries[targetIndex][1].id] = true;
        return newState;
      });
    }
  };

  const duplicateQuestion = () => {
    if (questionToDuplicate) {
      const newQuestions = { ...formDetails.questions };
      const questionEntries = Object.entries(newQuestions).sort(([, a], [, b]) => a.index - b.index);
      const questionIndex = questionEntries.findIndex(([key, question]) => question.id === questionToDuplicate);
      const newQuestionId = `question-${Date.now()}`; // Generate a new unique ID

      if (questionIndex !== -1) {
        const newQuestion = {
          ...questionEntries[questionIndex][1],
          id: newQuestionId,
          index: questionEntries[questionIndex][1].index + 1,
        };

        questionEntries.splice(questionIndex + 1, 0, [newQuestionId, newQuestion]);

        for (let i = questionIndex + 2; i < questionEntries.length; i++) {
          questionEntries[i][1].index += 1;
        }

        const reorderedQuestions = {};
        questionEntries.forEach(([key, question]) => {
          reorderedQuestions[key] = question;
        });

        setQuestions(reorderedQuestions);
        handleFormChange('questions', reorderedQuestions);
      }
    }
    setIsDuplicateOpen(false);
  };

  const toggleQuestion = (questionId) => {
    setOpenQuestions(prevState => ({
      ...prevState,
      [questionId]: !prevState[questionId], // Toggle only the clicked question
    }));

  };

  const renderQuestions = () => {
    const sortedQuestions = Object.entries(formDetails.questions).sort(([, a], [, b]) => a.index - b.index);

    return sortedQuestions.map(([key, question], index) => (
      <Box key={key} borderWidth="1px" borderRadius="md" overflow="hidden">
        <Box
          as="button"
          width="100%"
          textAlign="left"
          onClick={() => toggleQuestion(key)}
          bg={panelBg}
          p={4}
        >
          {t('question')} {index + 1}: {question.name}
        </Box>
        {openQuestions[key] && (
          <Box p={panelPadding} bg={panelBg}>
            <FormQuestion
              question={question}
              questionId={key}
              questionIndex={index}
              handleFormChange={handleFormChange}
            />
            <IconButton onClick={() => moveQuestion(question.id, -1)} isDisabled={index === 0} icon={<ChevronUpIcon />} />
            <IconButton onClick={() => moveQuestion(question.id, 1)} isDisabled={index === sortedQuestions.length - 1} icon={<ChevronDownIcon />} />
            <IconButton
              icon={<CopyIcon />}
              onClick={() => {
                setQuestionToDuplicate(question.id);
                setIsDuplicateOpen(true);
              }}
            />
          </Box>
        )}
      </Box>
    ));
  };

  const panelBg = useColorModeValue('gray.100', 'gray.700');
  const panelPadding = 4;

  return (
    <VStack align="stretch">
      {renderQuestions()}
      <Button colorScheme="gray" onClick={addNewQuestion}>{t('add_new_question')}</Button>

      <AlertDialog
        isOpen={isDuplicateOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDuplicateOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('duplicate_question')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t('are_you_sure_you_want_to_duplicate_this_question')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDuplicateOpen(false)}>
                {t('cancel')}
              </Button>
              <Button colorScheme="blue" onClick={duplicateQuestion} ml={3}>
                {t('duplicate')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default Questions;