// NewFormGenerator.js

import React, { useState } from 'react';
import { Input, Button, useToast, Spinner, Textarea, Box, Heading, Text, Center, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { AttachmentIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // You would need to install axios or use any other HTTP client
import { useTranslation } from 'react-i18next'; // Imported hook

import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist/legacy/build/pdf';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const NewAssessmentGenerator = () => {
  const [fileInput, setFileInput] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [textInstructions, setTextInstructions] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [pdfText, setPdfText] = useState('');
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    try{
    const file = event.target.files[0];
    if (file.type !== "application/pdf") {
      toast({
        title: 'Invalid file type',
        description: 'Please upload a PDF file',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setIsUploading(true);
    extractTextFromPdf(file);
    setFileName(file.name);
  } catch (error) {
    console.log(error);
  }
  };
  

  const extractTextFromPdf = (pdfFile) => {
    const fileReader = new FileReader();

    fileReader.onload = async (event) => {
      try {
        const typedArray = new Uint8Array(event.target.result);
        const pdfDocument = await getDocument(typedArray).promise;
        const textContentItems = [];

        for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
          const page = await pdfDocument.getPage(pageNum);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map(item => item.str).join(' ');
          textContentItems.push(pageText);
        }

        setPdfText(textContentItems.join('\n'));
        console.log('pdfText', textContentItems);
        setIsUploading(false);
      } catch (error) {
        console.error('Error while reading the PDF: ', error);
        toast({
          title: 'Error extracting text',
          description: 'There was an error extracting text from the PDF.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
      }
    };

    fileReader.readAsArrayBuffer(pdfFile);
  };


  const handleInstructionsChange = (event) => {
    setTextInstructions(event.target.value);
  };

  const handleFormGeneration = async () => {

    try {
      setIsLoading(true);
      const fileText = "";
      const textToSend = `${fileText}\n${textInstructions}`;

      // Send the text to the AI Service
      const aiResponse = await axios.post('https://eduscope-cfda5.web.app/api/runOpenAIAssistant', {
        message: textToSend,
        ...(fileId ? { file_ids: [fileId] } : {})
      });

      setIsLoading(false);
      let runID = aiResponse.data.id;
      let threadID = aiResponse.data.thread_id;
      console.log(`/admin/process_generation/${runID}/thread/${threadID}`);
      navigate(`/admin/process_generation/${runID}/thread/${threadID}`);

    } catch (error) {
      setIsLoading(false);
      toast({
        title: 'Error Generating Form',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Center flexDirection="column" p={4} textAlign="center">
      {!isLoading ? (
        <>
           <Heading mb={4}>{t('generate_new_assessment_heading')}</Heading>
           <Text mb={4}>{t('generate_new_assessment_subheading')}</Text>
           {isUploading && <Spinner />}

{/* Custom file upload button */}
<Button leftIcon={<AttachmentIcon />} colorScheme="blue" variant="outline" onClick={handleClick}>
  {fileName || t('upload_pdf_button')} {/* Display the file name if available */}
</Button>

{/* Hidden file input */}
<Input
  type="file"
  accept="application/pdf"
  onChange={handleFileChange}
  ref={hiddenFileInput}
  display="none"
/>

{/* Accordion for the editable PDF content */}
{pdfText && (
  <Accordion allowToggle width="100%">
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          {t('edit_pdf_content')}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <Textarea
          value={pdfText}
          onChange={(e) => setPdfText(e.target.value)}
          placeholder={t('pdf_content_placeholder')}
          size="lg"
        />
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
)}
          <Textarea
            onChange={handleInstructionsChange}
            placeholder={t('enter_instructions_placeholder')}
            mt={10}
            mb={3}
          />
          <Button
            colorScheme="blue"
            onClick={handleFormGeneration}
            isLoading={isLoading}
            isDisabled={isUploading}
          >
           {t('generate_assessment_button')}
          </Button>
        </>
      ) : (
        <Box mt="20">
          <Spinner size="xl" thickness="4px" speed="0.65s" mb={6} />
          <Heading mb={2}>{t('initiating_assessment_generation')}</Heading>
          <Text color={'gray.500'}>{t('please_wait')}</Text>
        </Box>
      )}
    </Center>
  );
};

export default NewAssessmentGenerator;