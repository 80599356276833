import React, { useState, useEffect } from 'react';
import {
  Box, Button, Table, Thead, Tbody, Tr, Th, Td, Text, Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, useDisclosure, useToast
} from '@chakra-ui/react';
import { getAssessments, getSubmissionQuery, getQuestions, getResponsesForForm, createExportRequest } from '../utils/firebase.utils';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '@chakra-ui/icons';

const AssessmentData = ({ accountId, assessmentId, userEmail }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [assessments, setAssessments] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsHash, setQuestionsHash] = useState({});
  const [lastVisible, setLastVisible] = useState(null);
  const [submissionsResponses, setSubmissionsResponses] = useState({});
  const [email, setEmail] = useState(userEmail);

  useEffect(() => {
    const fetchAssessments = async () => {
      if(accountId){
        const { assessments } = await getAssessments(accountId, assessmentId);
        const assessmentsHash = assessments.reduce((acc, assessment) => {
          acc[assessment.id] = assessment;
          return acc;
        }, {});

        setAssessments(assessmentsHash);
      }
    };

    fetchAssessments();
  }, [accountId]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      const { submissions, lastVisible } = await getSubmissionQuery(accountId, assessmentId);
      setSubmissions(submissions);
      setLastVisible(lastVisible);
    };

    fetchSubmissions();
  }, [accountId]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const questions = await getQuestions(accountId, assessmentId);
      const questionsHash = {};
      questions.forEach((question) => {
        questionsHash[question.id] = questionsHash[question.id] || {};
        Object.entries(question.options).forEach(([key, option]) => {
          if (option.value > 0) {
            questionsHash[question.id][key] = option.value;
          }
        });
      });
      setQuestions(questions);
      setQuestionsHash(questionsHash);
    };

    const fetchResponses = async () => {
      const responses = await getResponsesForForm(accountId, assessmentId);
      setSubmissionsResponses(responses);
    };

    if (assessmentId && accountId) {
      fetchQuestions();
      fetchResponses();
    }
  }, [assessmentId]);

  const fetchMoreSubmissions = async () => {
    const { submissions: newSubmissions, lastVisible: newLastVisible } = await getSubmissionQuery(accountId, assessmentId, lastVisible);
    setSubmissions(prev => [...prev, ...newSubmissions]);
    setLastVisible(newLastVisible);
  };

  const handleExport = async () => {
    try {
      await createExportRequest(accountId, assessmentId, email);
      toast({
        title: t('export_requested'),
        description: t('email_with_link'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: t('error'),
        description: t('export_error'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderSubmissionsTable = () => (
    <Box overflowX="auto">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t('tableHeaders.assessment')}</Th>
            <Th>{t('tableHeaders.userIdentifier')}</Th>
            <Th>{t('tableHeaders.score')}</Th>
            <Th>{t('tableHeaders.startTime')}</Th>
            <Th>{t('tableHeaders.isFinished')}</Th>
            {Object.entries(questions).map(([key, question]) => (
              <Th key={key}>
                <Tooltip label={question.name} aria-label={`Tooltip for ${question.title}`}>
                  <span>{parseInt(key) + 1}</span>
                </Tooltip>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {submissions.map(submission => (
            <Tr key={submission.id}>
              <Td>{submission.title}</Td>
              <Td>{submission.userIdentifier || submission.email}</Td>
              <Td>{submission.score || 0}{(assessments[submission.formId] || {}).totalPoints > 0 ? `/${assessments[submission.formId].totalPoints}` : '' }</Td>
              <Td>{submission.startTime ? submission.startTime.toLocaleString() : ''}</Td>
              <Td>{submission.isFinished ? t('tableHeaders.yes') : t('tableHeaders.no')}</Td>
              {questions.map((question) => {
                const response = (submissionsResponses[submission.id] || {})[question.id];
                const responseColor = response && questionsHash[question.id][response.id] ? 'green' : 'red';
                const responseSymbol = response && questionsHash[question.id][response.id] ? '✓' : '✗';
                const tooltipText = response ? response.name : t('tableHeaders.noResponse');
                return (
                  <Td key={question.id}>
                    <Tooltip label={tooltipText} aria-label="Response Tooltip">
                      <Text color={responseColor}>
                        {responseSymbol}
                      </Text>
                    </Tooltip>
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );

  return (
    <Box>
        <Box display="flex" justifyContent="flex-end" mt="10px">
          <Button size="sm" onClick={onOpen}>
            <DownloadIcon />
            {t('export_data')}
          </Button>
        </Box>

      {submissions.length > 0 ? renderSubmissionsTable() : <p>{t('messages.noSubmissions')}</p>}
      {lastVisible && (
        <Button mt={4} onClick={fetchMoreSubmissions}>
         {t('Load More')}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('export_data')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>{t('email')}</FormLabel>
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>{t('cancel')}</Button>
            <Button colorScheme="blue" onClick={handleExport} ml={3}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AssessmentData;