// FormQuestionOptions.js
import React, { useState } from 'react';
import {
  VStack,
  HStack,
  Input,
  Text,
  Button,
  NumberInput,
  NumberInputField,
  IconButton,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { CloseIcon , AttachmentIcon, DeleteIcon} from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { uploadImageToStorage } from '../../utils/firebase.utils';

const FormQuestionOptions = ({ options, handleFormChange, questionIndex, question }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadingImageForOptionId, setUploadingImageForOptionId] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const toast = useToast();

  const handleImageUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  

  const handleImageInteraction = (optionId) => {
    setSelectedOptionId(optionId);
    setUploadingImageForOptionId(optionId);
    setSelectedFile(null); // Clear any previously selected files
    onOpen();
  };

  const handleRemoveImage = (optionId) => {
    handleOptionChange(optionId, 'imageURL', null);
  };

  const handleUploadSubmit = async () => {
    if (selectedFile && uploadingImageForOptionId) {
      try {
        const imageURL = await uploadImageToStorage(selectedFile, `options/${uploadingImageForOptionId}`);
        handleOptionChange(uploadingImageForOptionId, 'imageURL', imageURL);
        onClose();
        toast({
          title: t('image_upload_success'),
          description: t('image_uploaded'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: t('image_upload_error'),
          description: t('image_upload_failed'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleOptionChange = (optionId, field, value) => {
    // Update an option by its ID
    const updatedOptions = {
      ...options,
      [optionId]: { ...options[optionId], [field]: value },
    };
    console.log('updated options', updatedOptions);
    // Update the question object with the new options
    handleFormChange('questions', { ...question, options: updatedOptions },  questionIndex);
  };

  const handleRemoveOption = (optionId) => {
    // Clone options, remove the option by ID
    const updatedOptions = { ...options };
    delete updatedOptions[optionId];
    // Update the question object without the removed option
    handleFormChange('questions',  { ...question, options: updatedOptions }, questionIndex);
  };

  const handleAddOption = () => {
    const newOptionId = uuidv4(); // Generate a unique ID for the new option
    const newOption = { name: '', value: 0 };
    // Add the new option to the question's options
    const updatedOptions = {
      ...options,
      [newOptionId]: newOption,
    };
    handleFormChange('questions',  { ...question, options: updatedOptions }, questionIndex);
  };


  const ImageUploadModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('upload_image')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Displaying previously uploaded image or image preview */}
          {options[selectedOptionId]?.imageURL || selectedFile ? (
            <Image
              src={
                selectedFile
                  ? URL.createObjectURL(selectedFile)
                  : options[selectedOptionId]?.imageURL
              }
              alt={t('uploaded_image')}
              maxH="300px"
              w="100%"
              mt={4}
              objectFit="contain"
            />
          ) : (
            <Input
              type="file"
              onChange={handleImageUpload}
              accept="image/*"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="ghost"
            onClick={handleUploadSubmit}
            disabled={!selectedFile} // Disable upload button if no file is selected
          >
            {t('upload')}
          </Button>
          {options[selectedOptionId]?.imageURL && ( // Show delete button only when the image URL exists
            <IconButton
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={() => handleRemoveImage(selectedOptionId)}
              aria-label={t('remove_image')}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
  

  return (
    <>
    <VStack align="stretch" ml={4}>
       <HStack justifyContent="space-between">
        <Text>{t('option_text')}</Text>
        <Text>{t('score')}</Text>
      </HStack>
      {Object.entries(options).map(([optionId, option]) => (
        <HStack 
          key={optionId} 
          bg={option.value > 0 ? 'green.100' : 'transparent'}
        >
          <Input
            placeholder="Option Text"
            value={option.name}
            onChange={(e) => handleOptionChange(optionId, 'name', e.target.value)}
          />
          <NumberInput
            maxW="100px"
            value={option.value}
            min={0}
            precision={0}
            onChange={(valueAsString) => handleOptionChange(optionId, 'value', valueAsString)}
          >
            <NumberInputField />
          </NumberInput>
          <IconButton
              icon={option.imageURL ? <AttachmentIcon color="blue.500" /> : <AttachmentIcon />}
              size="sm"
              onClick={() => handleImageInteraction(optionId)}
              aria-label={t('add_image')}
            />
          <IconButton
            icon={<CloseIcon />}
            size="sm"
            colorScheme="red"
            onClick={() => handleRemoveOption(optionId)}
            aria-label="Remove option"
          />
        </HStack>
      ))}
       <Button variant="outline" colorScheme="white" size="sm" onClick={handleAddOption}>{t('add_option')}</Button>
    </VStack>
       <ImageUploadModal />
       </>
  );
};

export default FormQuestionOptions;