// src/utils/urlUtils.js

/**
 * Parses the URL search parameters and stores userData in localStorage.
 */
export const captureAndStoreUserData = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const userData = {
      name: queryParams.get('name'),
      email: queryParams.get('email'),
      userId: queryParams.get('userId'),
      companyName: queryParams.get('companyName'),
      companyId: queryParams.get('companyId'),
      language: queryParams.get('language'),
      country: queryParams.get('country'),
      token: queryParams.get('token'),
      numUsers: queryParams.get('numUsers'),
    };
  
    // Store userData in localStorage if not already present or if token has changed
    const storedUserData = JSON.parse(localStorage.getItem('userData') || '{}');
    if (!storedUserData.token || storedUserData.token !== userData.token || storedUserData.email != userData.email  || storedUserData.language != userData.language) {
      const userDataMerge = {
        name: userData.name || storedUserData.name,
        email: userData.email || storedUserData.email,
        userId: userData.userId || storedUserData.userId,
        companyName: userData.companyName || storedUserData.companyName,
        companyId: userData.companyId || storedUserData.companyId,
        language: userData.language || storedUserData.language,
        country: userData.country || storedUserData.country,
        token: userData.token || storedUserData.token,
        numUsers: userData.numUsers || storedUserData.numUsers,
      };
      localStorage.setItem('userData', JSON.stringify(userDataMerge));
    }
  
    return userData;
  };