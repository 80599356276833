// AdvancedConfiguration.js
import React,{ useState, useRef, useCallback } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Button,
  Switch,
  Textarea,
  HStack,
  Text,
  Link,
  Select,
  Input,
  NumberInput,
  NumberInputField,
  Icon,
  useToast
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { uploadPdfToStorage } from '../../utils/firebase.utils'
import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons';
import InviteUsersForm from './InviteUsersForm';

const MAX_PDF_SIZE = 15 * 1024 * 1024; // 15 MB in bytes

const AdvancedConfiguration = ({ formDetails, handleFormChange, accountId }) => {
  console.log('current time', formDetails.time);
  const { t } = useTranslation();
  const toast = useToast();
  const fileInputRef = useRef(null);

  
  // Handler for when the country is selected
  const handleCountrySelection = (event) => {
    handleFormChange('country', event.target.value);
  };

  const togglePrivateAssessment = () => {
    // Corrected the toggle logic to properly update the state
    const newValue = !formDetails.privateAssessment;
    handleFormChange('privateAssessment', newValue);
  };

  // Handler for when the custom identifier is changed
  const handleCustomIdentifierChange = (event) => {
    handleFormChange('customIdentifier', event.target.value);
  };

  const toggleRequireAccount = () => {
    handleFormChange('requireAccount', !formDetails.requireAccount);
  };

  // Handler for "Ask for Identifier" switch
  const toggleAskForIdentifier = () => {
    handleFormChange('askForIdentifier', !formDetails.askForIdentifier);
  };

  const handleAuthenticatorTypeChange = (event) => {
    handleFormChange('authenticatorType', event.target.value);
  };

  const handleVideoLinkChange = (event) => {
    handleFormChange('videoLink', event.target.value);
  };

  const removePdf = () => {
    handleFormChange('pdfDocument', ''); // Clear the PDF URL
  };


  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Checking the file type and size
      if (file.type !== "application/pdf") {
        toast({
          title: t('error_not_pdf'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (file.size > MAX_PDF_SIZE) {
        toast({
          title: t('error_pdf_too_large'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      try {
        const pdfUrl = await uploadPdfToStorage(file);
        handleFormChange('pdfDocument', pdfUrl);
        toast({
          title: t('pdf_uploaded_successfully'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: t('error_uploading_pdf'),
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  
  return (
    <VStack spacing={4} align="stretch">
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="ask-for-identifier" mb="0">{t('ask_for_identifier')}</FormLabel>
        <Switch
          id="ask-for-identifier"
          isChecked={formDetails.askForIdentifier || false}
          onChange={toggleAskForIdentifier}
        />
      </FormControl>

      {formDetails.askForIdentifier && (
        <FormControl>
          <FormLabel htmlFor="authenticator-type">{t('authentication_type')}</FormLabel>
          <Select id="authenticator-type" value={formDetails.authenticatorType || ''} onChange={handleAuthenticatorTypeChange}>
            <option value="country">{t('country_validated_authenticator')}</option>
            <option value="custom">{t('custom_authenticator')}</option>
          </Select>
        </FormControl>
      )}

      {formDetails.authenticatorType == 'country' && formDetails.askForIdentifier && (
        <FormControl>
          <FormLabel htmlFor="select-country">{t('select_country')}</FormLabel>
          <Select id="select-country" value={formDetails.country || ''} onChange={handleCountrySelection}>
            <option value="CL">{t('chile')}</option>
            <option value="MX">{t('mexico')}</option>
          </Select>
        </FormControl>
      )}

      {formDetails.authenticatorType === 'custom' && (
        <FormControl>
          <FormLabel htmlFor="custom-identifier">{t('custom_identifier')}</FormLabel>
          <Input
            id="custom-identifier"
            placeholder={t('custom_identifier_name')}
            value={formDetails.customIdentifier || ''}
            onChange={handleCustomIdentifierChange}
          />
        </FormControl>
      )}
      
  <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="random-order" mb="0">
          {t('randomize_question_order')}
        </FormLabel>
        <Switch id="random-order" isChecked={formDetails.randomOrder} onChange={(e) => handleFormChange('randomOrder', e.target.checked)} />
      </FormControl>

      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="skip-feedback" mb="0">
          {t('skip_feedback')}
        </FormLabel>
        <Switch id="skip-feedback" isChecked={formDetails.showFeedback} onChange={(e) => handleFormChange('showFeedback', e.target.checked)} />
      </FormControl>


      <FormControl>
        <FormLabel htmlFor="description">{t('form_description')}</FormLabel>
        <Textarea 
          id="description" 
          placeholder={t('form_description_placeholder')} 
          value={formDetails.description} 
          onChange={(e) => handleFormChange('description', e.target.value)} 
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="instructions">{t('form_instructions')}</FormLabel>
        <ReactQuill
          theme="snow"
          value={formDetails.instructions}
          onChange={(content) => handleFormChange('instructions', content)}
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="video-link">{t('intro_video_link')}</FormLabel>
        <Input
          id="video-link"
          placeholder={t('intro_video_placeholder')}
          value={formDetails.videoLink}
          onChange={handleVideoLinkChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="pdf-document">{t('upload_pdf_document')}</FormLabel>
        <HStack>
          {formDetails.pdfDocument ? (
            <>
              <Link href={formDetails.pdfDocument} isExternal>
                <Button leftIcon={<AttachmentIcon />} variant="outline" size="sm">
                  {t('view_pdf')}
                </Button>
              </Link>
              <Button size="sm" variant="ghost" onClick={removePdf}>
                <DeleteIcon />
              </Button>
            </>
          ) : (
            <>
              <Input
                id="pdf-document"
                type="file"
                accept="application/pdf"
                ref={fileInputRef}
                onChange={handlePdfUpload}
                hidden
              />
              <Button onClick={() => fileInputRef.current.click()} leftIcon={<AttachmentIcon />}>
                {t('upload_pdf')}
              </Button>
            </>
          )}
        </HStack>
      </FormControl>


      <FormControl>
        <FormLabel htmlFor="time">{t('time_in_minutes')}</FormLabel>
        <NumberInput 
          id="time" 
          maxW="100px" 
          value={parseInt(formDetails.time)} 
          defaultValue={parseInt(formDetails.time)} 
          min={0} 
          onChange={(valueString) => handleFormChange('time', valueString)}
        >
          <NumberInputField />
        </NumberInput>
      </FormControl>

      {/* Additional advanced configuration options can be added here */}
    </VStack>
  );
};

export default AdvancedConfiguration;
