import React, { useState, useEffect } from 'react';
import StartTrial from './StartTrial';
import TrialExpired from './TrialExpired';
import {  startTrialForAccount } from '../utils/firebase.utils'; // Import the utility functions
import { useTranslation } from 'react-i18next';
import {Alert, AlertIcon, AlertTitle, Button } from '@chakra-ui/react';
import {  QuestionIcon } from '@chakra-ui/icons';

const AssessmentValidation = ({ accountId, status, accountData }) => {

  const { t } = useTranslation();

  const calculateDaysLeft = (trialEndTimestamp) => {
    try {
      if (!trialEndTimestamp || typeof trialEndTimestamp.toDate !== 'function') {
        throw new Error("Invalid Timestamp");
      }
      const today = new Date();
      const trialEnd = trialEndTimestamp.toDate();
      const differenceInTime = trialEnd.getTime() - today.getTime();
      return Math.max(0, Math.ceil(differenceInTime / (1000 * 3600 * 24)));  // Ensure non-negative days calculation
    } catch (error) {
      console.error("Error calculating days left:", error);
      return null;
    }
  };

  const handleStartTrial = async () => {
    try {
      await startTrialForAccount(String(accountId));
    } catch (error) {
      console.error('Error starting trial:', error);
    }
  };

  const renderComponent = () => {
    switch (status) {
      case 'onTrial':
        const daysLeft = calculateDaysLeft(accountData.trialEnd);
        return daysLeft !== null ? (
          <Alert status="info" alignItems="center" justifyContent="space-between">
          <AlertIcon />
          <AlertTitle ml={2}>
            {daysLeft > 1 ? t('trial.daysLeft_plural', { count: daysLeft }) : t('trial.daysLeft', { count: daysLeft })}
          </AlertTitle>
          <Button as="a" href="https://eduscope.feedbear.com/boards/feature-requests" target="_blank" rel="noopener noreferrer" bg="rgb(241, 128, 13)" color="white" mr={4} _hover={{ bg: "rgb(221, 108, 0)" }} size="sm" borderRadius="full" leftIcon={<QuestionIcon />} > {t('feedback_button')} </Button>
        </Alert>
        ) : ( <></>    );
      case 'expired':
        return <TrialExpired accountData={accountData} accountId={accountId} />;
      case 'noTrial':
        return <StartTrial onStartTrial={handleStartTrial} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {renderComponent()}
    </>
  );
};

export default AssessmentValidation;