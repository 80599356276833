import { VStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const QuestionResponseFeedback = ({ question, response }) => {
  const { t } = useTranslation();
  // Placeholder - implement feedback based on correct answer and given response
  const isCorrect = question.correctAnswer === response; 
  const bgColor = isCorrect ? 'green.100' : 'red.100';
  const feedbackText = question.feedback || '';

  return (
    <VStack 
    bg={bgColor} p={4} borderRadius="md" borderWidth="1px"
    borderColor={isCorrect ? 'green.300' : 'red.300'}
  >
    <Text fontWeight="bold">{isCorrect ? t('correct') : t('wrong')}</Text>
    {feedbackText && <Text>{feedbackText}</Text>}
  </VStack>
  );
};

export default QuestionResponseFeedback;