import React from 'react';
import { Box, Heading, Text, Button, VStack, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';

const ScheduleMeeting = () => {
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const { numUsers } = userData;
  const navigate = useNavigate();

  // Check if numUsers is not null and greater than 50
  const shouldShowCalendar = numUsers !== null && numUsers > 50;

  return (
    <Box paddingX="10%" paddingY="5%">
    <Button leftIcon={<FaArrowLeft />} colorScheme="blue" variant="ghost" onClick={() => navigate(-2)}></Button>
      {shouldShowCalendar ? (
        <>
          <Heading marginBottom="4">{t('scheduleMeetingTitle')}</Heading>
          <Text marginBottom="6">{t('scheduleMeetingSubtitle')}</Text>
          <iframe
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3-SKAQYakSRI5KeSxGiKbjGq2UjII69-p41Y1Kegg9HgsNpCV6zmO8CfTWS5QIutNerHDj9LDa?gv=true"
            style={{border: 0}}
            width="100%"
            height="600"
            frameborder="0"
            title="Schedule Meeting"
          ></iframe>
        </>
      ) : (
        <VStack 
      spacing={4} 
      p={5} 
      boxShadow='lg' // Add a subtle shadow to lift the card off the page
      rounded='md' // Smooth the corners a bit
      bg='blue.50' // Soft background color for the card
      align='center' // Center-align the child elements
      maxWidth='400px' // Set a max width to avoid overly wide cards
      margin='0 auto' // Center the card horizontally on the page
      mt={10} // Add margin top for spacing from previous content
    >
      <Icon as={FiCheckCircle} w={10} h={10} color="green.500" /> {/* Example icon with styling */}
      <Heading as="h3" size="md" color='blue.800'>
        {t('contactSoonMessageTitle')} {/* Assuming you have a title translation */}
      </Heading>
      <Text fontSize="md" color='blue.600'>
        {t('contactSoonMessage')} {/* Your original message as a subtitle */}
      </Text>
    </VStack>

      )}
    </Box>
  );
};

export default ScheduleMeeting;