import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Heading, Text, Icon, Button, VStack, Center, Container, useColorModeValue
} from '@chakra-ui/react';
// Import your chosen icons
import { FaBrain, FaLaptopCode, FaPuzzlePiece, FaRocket, FaInfoCircle } from 'react-icons/fa';

const Feature = ({ title, subtitle, icon }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const color = useColorModeValue('primary', 'white');
  
  return (
    <Box
      p={5}
      backgroundColor="#F3F6FB"
      borderColor=""
      boxShadow="lg"
      rounded="xl"
      w="100%"
    >
      <VStack spacing={3} alignItems="center">
        <Icon as={icon} color="#3E8FFA" w={12} h={12} />
        <Heading size="lg" fontWeight="bold" color={color}>
          {title}
        </Heading>
        <Text fontSize="md" color="text" textAlign="center">{subtitle}</Text>
      </VStack>
    </Box>
  );
};

const Training = () => {
  const { t } = useTranslation();
  
  return (
    <Container maxW="container.xl" py={12}>
      <VStack spacing={10} alignItems="center">
        <VStack alignItems="start" spacing={3}>
          <Heading color="telegram-900" textAlign="center">{t('training.title')}</Heading>
          <Text color="secondary" textAlign="center">{t('training.subtitle')}</Text>
        </VStack>

        <Center w="100%">
      <Button
        bg="#3E8FFA" // Background color
        color="white" // Text color
        size="lg"
        py={7}
        px={10}
        rounded="xl"
        onClick={() => { window.location.href = "/iframe/requestInfo"; }}
        boxShadow="lg"
        fontSize="lg"
        _hover={{ bg: "blue.600" }} // A slightly darker blue on hover
        _active={{ bg: "blue.700" }} // Even darker blue when the button is clicked
      >
             {t('training.requestInfoButton')}
      </Button>
    </Center>

        {/* Features */}
        <Box w="100%">
          <VStack spacing={8} alignItems="stretch" m="0 auto">
            {/* Content and Courses Creation */}
            <Feature
              title={t('training.features.contentCreation.title')}
              subtitle={t('training.features.contentCreation.subtitle')}
              icon={FaLaptopCode} // Replace with your icon
            />

            {/* Internal Assessments */}
            <Feature
              title={t('training.features.internalAssessments.title')}
              subtitle={t('training.features.internalAssessments.subtitle')}
              icon={FaBrain} // Replace with your icon
            />

            {/* Gamification */}
            <Feature
              title={t('training.features.gamification.title')}
              subtitle={t('training.features.gamification.subtitle')}
              icon={FaPuzzlePiece} // Replace with your icon
            />

            {/* AI Potential */}
            <Feature
              title={t('training.features.aiPotential.title')}
              subtitle={t('training.features.aiPotential.subtitle')}
              icon={FaRocket} // Replace with your icon
            />
          </VStack>
        </Box>

        {/* Call to Action */}
        <Box w="100%" pt={5}>
          <Text color="secondary" mb={4} textAlign="center">
            {t('training.requestInfo')}
          </Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default Training;