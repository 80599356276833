// src/components/RequestMoreInfoForm.js
import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, FormControl, FormLabel, Checkbox, CheckboxGroup, Stack, Textarea, Select, useToast, Heading } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { saveRequestDetails } from '../utils/firebase.utils'; // Ensure this function exists and correctly handles Firestore operations
import { useTranslation } from 'react-i18next';

const RequestMoreInfoForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    let dataMerge = { ...data, userData: JSON.parse(localStorage.getItem('userData') || '{}') };
    try {
      await saveRequestDetails(dataMerge);
      reset();
      // Navigate to a success page or go back to the previous page
      navigate('/iframe/confirm'); // Replace with the actual route
    } catch (error) {
      console.error("Error saving request details:", error);
      toast({
        title: t('requestInfoForm.errorOccurredTitle'),
        description: t('requestInfoForm.errorOccurredDescription'),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={8}>
      <Heading as="h2" size="xl" textAlign="center" mb={6}>
        {t('requestInfoForm.title')}
      </Heading>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="feature-interest" >
          <FormLabel>{t('requestInfoForm.featuresLabel')}</FormLabel>
          <CheckboxGroup defaultValue={[]}>
            <Stack spacing={2} direction="column">
              <Checkbox value="contentCreation" {...register('features.contentCreation')}>
                {t('training.features.contentCreation.title')}
              </Checkbox>
              <Checkbox value="internalAssessments" {...register('features.internalAssessments')}>
                {t('training.features.internalAssessments.title')}
              </Checkbox>
              <Checkbox value="gamification" {...register('features.gamification')}>
                {t('training.features.gamification.title')}
              </Checkbox>
              <Checkbox value="aiPotential" {...register('features.aiPotential')}>
                {t('training.features.aiPotential.title')}
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </FormControl>

        <FormControl id="problem-description" isRequired mt={6}>
          <FormLabel>{t('requestInfoForm.problemLabel')}</FormLabel>
          <Textarea placeholder={t('requestInfoForm.problemPlaceholder')} {...register('problemDescription')} />
        </FormControl>

        <FormControl id="number-of-users" isRequired mt={6}>
          <FormLabel>{t('requestInfoForm.numUsersLabel')}</FormLabel>
          <Select id="numUsers" placeholder={t('requestInfoForm.selectPlaceholder')} {...register('numUsers')}>
            {["0-10", "10-50", "50-100", "100-500", "500-1000", "1000+", "3000+"].map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </Select>
        </FormControl>

        <Button mt={6} width="full" colorScheme="blue" type="submit">
          {t('requestInfoForm.submitButton')}
        </Button>
      </form>
    </Box>
  );
};

export default RequestMoreInfoForm;