// FormBuilder.js
import React, { useState, useEffect } from 'react';
import {
  VStack,
  Button,
  Flex,
  Box,
  useToast,
  Tabs,
  IconButton,
  TabList,
  useDisclosure,
  Spinner,
  Icon,
  TabPanels,
  Tab,
  TabPanel
} from '@chakra-ui/react';
import GeneralConfiguration from './GeneralConfiguration';
import AdvancedConfiguration from './AdvancedConfiguration';
import { FaCog, FaList, FaChartBar, FaEye, FaShareAlt, FaArrowLeft } from 'react-icons/fa'; 
import { getForm, updateForm } from '../../utils/firebase.utils';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AssessmentData from '../AssessmentsData';
import { useNavigate } from 'react-router';
import ShareFormPopup from './ShareFormPopup';


function AssessmentBuilder({ accountId, initialFormDetails }) {
  const { formId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  
  const [formDetails, setFormDetails] = useState({
    title: '',
    description: '',
    instructions: '',
    time: 60,
    questions: [],
    // Add other form fields as needed for advanced configuration
  });

  const [isFormChanged, setIsFormChanged] = useState(false); 
  const [loading, setLoading] = useState(false);
  const [activeComponent, setActiveComponent] = useState('general');
  
  const toast = useToast();
  const { t } = useTranslation();


  const previewForm = () => {
    const url = `/assessment/${accountId}/${formId}?preview=true`;
    window.open(url, '_blank'); // This will open the URL in a new tab.
  };

  const goBack = () => {
    navigate('/admin/assessments'); // Adjust the URL to the path where the assessments are listed
  };

  const shareForm = () => {
    // Pending implementation
    onOpen();
  };

 
  useEffect(() => {
    async function fetchForm() {
      if(!accountId){
        return;
      }
      setLoading(true);
      if (initialFormDetails) {
        setFormDetails(initialFormDetails);
      } else if (formId && accountId) {
        try {
          const formData = await getForm(accountId, formId);
          if (formData) {
            setFormDetails({
              ...formData  // Populate with the entire formData object
            });
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error('Error loading form:', error);
          toast({
            title: t('error_loading_form'),
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
      
        }
      }
    }

    fetchForm();  // Call the async function defined inside useEffect
  }, [formId]);

  function debounce(func, wait) {
    let timeout;
  
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  
const handleFormChange = async (field, value, questionId = null, autosave = false) => {
  const newFormDetails = { ...formDetails };

  if (field === 'questions' && questionId !== null) {
    if (value === null) {
      // Remove the question by its ID
      delete newFormDetails.questions[questionId];
    } else {
      // Update the entire question by its ID with the new question object passed in value
      newFormDetails.questions[questionId] = { ...value };
    }
  } else {
    // Update top-level attribute
    newFormDetails[field] = value;
  }

  setFormDetails(newFormDetails);

  if (autosave) {
    try {
      await updateForm(accountId, { [field]: value }, formDetails.id);
      // Show success toast here if needed
    } catch (error) {
      // Show error toast here if needed
      console.error("Error saving form", error);
      // Optionally revert to previous state if the save fails
      setFormDetails(formDetails);
    }
  }
};

  const handleSaveForm = async () => {

    try {
      await updateForm(accountId, formDetails, formId);
      toast({
        title: t('success_save_form'),
        description: t('form_updated_successfully'),
        status: 'success',
        duration: 5000,
        isClosable: true
      });
    } catch (error) {
      console.log('error saving form', error);
      toast({
        title: t('error_save_form'),
        description: t('issue_saving_form'),
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };


  const tabContent = loading ? (
    <Spinner />
  ) : (
    <Tabs isFitted variant="enclosed" w="full" h="full" index={selectedTabIndex}
    onChange={(index) => setSelectedTabIndex(index)}>
    <TabList mb='1em'>
  <Tab>
    {t('questions')}
    <Box as="span" ml={2}><FaList /></Box>
  </Tab>
  <Tab>
    {t('results')}
    <Box as="span" ml={2}><FaChartBar /></Box>
  </Tab>
  <Tab>
    {t('settings')}
    <Box as="span" ml={2}><FaCog /></Box>
  </Tab>
</TabList>
      <TabPanels>
        <TabPanel>
        {selectedTabIndex === 0 && (
            <GeneralConfiguration
              formDetails={formDetails}
              handleFormChange={handleFormChange}
              accountId={accountId}
              saveForm={handleSaveForm}
            />
          )}
        </TabPanel>
        <TabPanel>
        {selectedTabIndex === 1 && (
            <AssessmentData
              accountId={accountId}
              assessmentId={formId}
            />
          )}
        </TabPanel>
        <TabPanel>
        {selectedTabIndex === 2 && (
            <AdvancedConfiguration
              formDetails={formDetails}
              handleFormChange={handleFormChange}
              accountId={accountId}
            />
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box borderWidth={1} borderRadius={8} boxShadow="lg" p={5} w="80%">
      <Flex justify="space-between" align="center" mb={4} width="full">
  <IconButton
    icon={<FaArrowLeft />}
    aria-label={t('back')}
    onClick={goBack}
    size="sm"
    variant="outline"
  />
  <Flex justify="flex-end">
    <Button
      colorScheme="blue"
      aria-label={t('share_form')}
      leftIcon={<FaShareAlt />}
      onClick={shareForm}
      size="sm"
      mr={2}
    >
      {t('share')}
    </Button>
    <ShareFormPopup
        isOpen={isOpen}
        onClose={onClose}
        formDetails={formDetails}
        handleFormChange={handleFormChange}
        accountId={accountId}
      />
    <IconButton
      variant="outline"
      colorScheme="blue"
      aria-label={t('preview_form')}
      icon={<FaEye />}
      onClick={previewForm}
      size="sm"
    />
  </Flex>
</Flex>
        {tabContent}
        <VStack spacing={4} mt={4} align="stretch">
          <Button colorScheme="blue" onClick={handleSaveForm}>
            {t('save_form')}
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
}

export default AssessmentBuilder;