import React, { useState, useEffect } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Text,
  HStack,
  useToast,
  IconButton
} from '@chakra-ui/react';
import { AddIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import {submitInvitations,  getInvitations, deleteInvitation } from '../../utils/firebase.utils';

const InviteUsersForm = ({ formId, accountId }) => {
  const [emailInput, setEmailInput] = useState('');
  const [emails, setEmails] = useState([]);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const { t } = useTranslation();
  const toast = useToast();


  useEffect(() => {
    const unsubscribe = getInvitations(formId, accountId, setInvitedEmails);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [formId, setInvitedEmails]);

  const addEmailsToList = () => {
    const emailList = emailInput.split(/[\n,]+/).map(email => email.trim()).filter(email => email);
    const newEmails = validateEmails(emailList);
    if (newEmails.length > 0) {
      setEmails([...emails, ...newEmails]);
      setEmailInput('');
    }
  };

  const validateEmails = (emailList) => {
    const validEmails = emailList.filter(email => validateEmail(email));
    const invalidEmails = emailList.filter(email => !validateEmail(email));
    
    if (invalidEmails.length > 0) {
      toast({
        title: t('invalid_email_address', { count: invalidEmails.length }),
        description: invalidEmails.join(', '),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    
    return validEmails;
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const removeEmail = (indexToRemove) => {
    setEmails(emails.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async () => {
    try {
      await submitInvitations(formId, accountId, emails); // Use utility to handle Firestore write
      setEmails([]); // Clear email list on successful submission
      toast({
        title: t('invitations_sent'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
        console.log(error);
      toast({
        title: t('error_sending_invitations'),
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteInvitation = async (invitationId) => {
    try {
      await deleteInvitation(formId, accountId, invitationId);
      toast({
        title: t('invitation_deleted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: t('error_deleting_invitation'),
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel htmlFor='invite-emails'>{t('invite_users')}</FormLabel>
        <Textarea
          id='invite-emails'
          placeholder={t('enter_email_addresses')}
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
        <Button mt={2} onClick={addEmailsToList} leftIcon={<AddIcon />}>
          {t('add_emails')}
        </Button>
      </FormControl>

      {emails.length > 0 && (
        <VStack align="stretch" mt={2}>
          {emails.map((email, index) => (
            <HStack key={index} justifyContent='space-between'>
              <Text isTruncated maxWidth="90%">{email}</Text>
              <IconButton
                size="sm"
                aria-label={t('remove_email')}
                icon={<CloseIcon />}
                onClick={() => removeEmail(index)}
              />
            </HStack>
          ))}
          <Button onClick={handleSubmit} isDisabled={emails.length === 0} colorScheme='blue'>
            {t('send_invites')}
          </Button>
        </VStack>
      )}

      {invitedEmails.length > 0 && (
        <VStack align="stretch" mt={2}>
          {invitedEmails.map(invite => (
            <HStack key={invite.id} justifyContent='space-between'>
              <Text isTruncated maxWidth="90%">{invite.email}</Text>
              <IconButton
                aria-label={t('delete_invitation')}
                icon={<DeleteIcon />}
                onClick={() => handleDeleteInvitation(invite.id)}
              />
            </HStack>
          ))}
        </VStack>
      )}
    </VStack>
  );

};

export default InviteUsersForm;