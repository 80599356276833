// GeneralConfiguration.js
import React, { useEffect } from 'react';
import { FormControl, FormLabel, Input, Flex, Button,  VStack, useBreakpointValue, IconButton } from '@chakra-ui/react';
import Questions from './Questions';
import { v4 as uuidv4 } from 'uuid';
import { AddIcon, CheckIcon, ViewIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';


const GeneralConfiguration = ({ formDetails, handleFormChange, saveForm, accountId }) => {
  const actionPanelPosition = useBreakpointValue({ base: 'fixed', md: 'sticky' });
  const direction = useBreakpointValue({ base: 'row', md: 'column' });
  const buttonSize = useBreakpointValue({ base: 'icon', md: 'full' });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  
  const navigate = useNavigate();

  useEffect(() => {
  }, [formDetails]);

  const previewForm = () => {
    navigate(`/assessment/${accountId}/${formDetails.id}`);
  };

  const addNewQuestion = () => {
    const newQuestionId = uuidv4();
    const newQuestionIndex = Math.max(...Object.values(formDetails.questions).map(question => question.index)) + 1;

    const newQuestion = {
      id: newQuestionId,
      name: t('new_question'),
      type: 'multi-option',
      options: {
        [uuidv4()]: { name: `${t('option')} 1`, value: 1 },
        [uuidv4()]: { name: `${t('option')} 2`, value: 0 },
        [uuidv4()]: { name: `${t('option')} 3`, value: 0 },
      },
      skills: [],
      index: newQuestionIndex,
    };

    // Create a new object for questions to trigger re-render
    handleFormChange('questions', { ...formDetails.questions, [newQuestionId]: newQuestion });
  };

  return (
    <Flex
    pos="relative"
    direction="column"
    p={4}
  >
    <VStack align="stretch" spacing={5}>
      <FormControl id="form-title-control">
        <FormLabel fontSize="lg" fontWeight="bold">{t('assessment_title')}</FormLabel>
        <Input
          id="title"
          placeholder={t('enter_assessment_title')}
          value={formDetails.title}
          onChange={(e) => handleFormChange('title', e.target.value)}
          size="lg"
        />
      </FormControl>
      <Questions formDetails={formDetails} handleFormChange={handleFormChange} addNewQuestion={addNewQuestion} />
    </VStack>
    </Flex>
  );
};

export default GeneralConfiguration;