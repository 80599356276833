import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react'; 
import { ChakraProvider, Center, Spinner, Text, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Assessment from './assessment/Assessment';
import AssessmentBuilder from './admin/assessment-builder/AssessmentBuilder';
import Configuration from './admin/Configuration';
import Assessments from './admin/Assessments';
import AssessmentsData from './admin/AssessmentsData';
import AdminComponent from './admin/AdminComponent';
import NewAssessmentGenerator from './admin/assessment-builder/NewAssessmentGenerator';
import ProcessorFormAIGenerator from './admin/assessment-builder/ProcessorFormAIGenerator';
import Login from './Login';
import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';
import {auth, getUserData } from './utils/firebase.utils';
import Training from './iframe/Training';
import i18n from './i18n';
import qs from 'qs';

import { captureAndStoreUserData } from './utils/urlUtils';
import RequestMoreInfoForm from './iframe/RequestMoreInfoForm';
import ScheduleMeeting from './iframe/ScheduleMeeting';
import AssessmentValidation from './admin/AssessmentValidation';

// Create a custom theme with the new blue color
const theme = extendTheme({
  colors: {
    blue: {
      50: '#ebf5ff',
      100: '#d1e6ff', 
      200: '#a8d1ff',
      300: '#7fb9ff',
      400: '#5ca1ff',
      500: 'rgb(62, 143, 250)', // Main blue color
      600: '#3576cc',
      700: '#265999',
      800: '#1a3d66',
      900: '#0d1f33',
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accountId, setAccountId] = useState(null);
  const [checkedSession, setCheckedSession] = useState(false);


  useEffect(() => {
    captureAndStoreUserData();
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    if(userData.language){
      i18n.changeLanguage(userData.language);
    }

    const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const iframeToken = urlParams.token;

    // If iframeToken is present, store it in localStorage
    let token = localStorage.getItem('iframeToken');
    if (iframeToken && localStorage.getItem('iframeToken') !== iframeToken) {
      localStorage.setItem('iframeToken', iframeToken);
      token = localStorage.getItem('iframeToken');
    }



    const fetchAccountInfo = async (token) => {
      try {
        const baseUrl = "https://us-central1-eduscope-cfda5.cloudfunctions.net/verifySession";

        const response = await fetch(baseUrl + '?token=' + token, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Return the entire data object, which includes accountId and possibly user info
          return data;
        } else {
          throw new Error('Failed to fetch accountId');
        }
      } catch (error) {
        console.error('Error fetching accountId:', error);
        return null;
      }
    };

    const checkSession = async (token) => {
      const data = await fetchAccountInfo(token);
      if (data && data.token && data.user) {
        try {
          // Sign in with custom token
          await signInWithCustomToken(auth, data.token);
        } catch (error) {
          console.error('Error signing in with custom token:', error);
        }
        setLoading(false);
        setCheckedSession(true);
        setLoading(false);
        return;
      }

      setCheckedSession(true);
      setLoading(false);

    
    };

    if(!!token && token != 'null'){
      checkSession(token);
    }
    else{
      setCheckedSession(true);

    }

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('auth changed');
      setUser(user);
      if (user) {
        console.log('user', user);
        const userData = await getUserData(user.uid);
        const decodedToken = await user.getIdTokenResult();
        const customClaims = decodedToken.claims;
        if(accountId != String(userData.accountId || user.uid) ){
          let cleanAccountID = String(userData.accountId || user.uid).trim();
          setAccountId(cleanAccountID);
        }
      } else {
        localStorage.setItem('iframeToken', null);
        setAccountId(null);
      }
      setLoading(false);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);
  
  if (loading || !checkedSession) { // Show loading spinner while determining authentication state
    return (
      <Center height="100vh" flexDirection="column" alignItems="center" justifyContent="center">
      <Spinner size="lg" w="150px" h="150px" />
    </Center>
    );
  };

  return (
    <ChakraProvider theme={theme}>
       <Router>
          <Routes>
            <Route path="/admin/configuration" element={
              user ? (
              <AdminComponent user={user}  accountId={accountId}>
                <Configuration user={user} />
              </AdminComponent>) :  <Login />
            }/>
            <Route path="/admin/assessments" element={
              user ? (
              <AdminComponent user={user}  accountId={accountId}>
                <Assessments user={user} accountId={accountId} />
              </AdminComponent>) :  <Login />
            }/>
            <Route path="/admin/assessments_data" element={
              user ? (
              <AdminComponent user={user}  accountId={accountId}>
                <AssessmentsData user={user} accountId={accountId} />
              </AdminComponent>) :  <Login />
            }/>
            <Route path="/admin/new_assessment" element={ user ? 
              (<AdminComponent user={user}  accountId={accountId}>
                <NewAssessmentGenerator accountId={accountId} user={user}  />
              </AdminComponent>) :  <Login />
            }/>
            <Route path="/admin/process_generation/:runID/thread/:threadID" element={
              user ? 
              (<AdminComponent user={user}  accountId={accountId}>
                <ProcessorFormAIGenerator user={user} accountId={accountId} />
              </AdminComponent>) :  <Login />
            } />
            <Route path="/admin/assessments_builder/:formId" element={
              user ? (
              <AdminComponent user={user}  accountId={accountId}>
                <AssessmentBuilder  accountId={String(accountId)} user={user} />
              </AdminComponent>) :  <Login />
            } />
            <Route path="/assessment/:accountId/:formId" element={<Assessment user={user}  />} />
            <Route path="/" element={
              user ? (
              <AdminComponent user={user}  accountId={accountId}>
                <Assessments user={user} accountId={accountId} />
              </AdminComponent>) :  <Login />
            }/>
            <Route path="/iframe/home" element={ <AdminComponent user={user}  accountId={accountId}><Assessments user={user} accountId={accountId} /> </AdminComponent> } />
            <Route path="/iframe/" element={<Training />} />
            <Route path="/iframe/requestInfo" element={<RequestMoreInfoForm />} />
            <Route path="/iframe/confirm" element={<ScheduleMeeting />} />
          </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
