import React from 'react';
import { useTranslation } from 'react-i18next';
import {Container, Text, VStack, Button, useToast } from '@chakra-ui/react';
import { saveRequestDetails } from '../utils/firebase.utils';

const TrialExpired = ({accountData, accountId}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleRequestActivation = async () => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    
    const requestData = {
      account_id: accountId,
      email: accountData.email,
      message: "User request activate eduScope"
    };


    try {
      await saveRequestDetails(requestData);
      toast({
        title: t('trialExpired.requestSent'),
        description: t('trialExpired.requestSentDescription'),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving activation request:", error);
      toast({
        title: t('trialExpired.errorOccurredTitle'),
        description: t('trialExpired.errorOccurredDescription'),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };


  return (
    <Container maxW="container.md" py={12}>
      <VStack spacing={8} alignItems="center">
        <Text fontSize="xl" fontWeight="bold" color="orange.500">
          {t('trialExpired.title')}
        </Text>
        <Text color="gray.700" textAlign="center">
          {t('trialExpired.message')}
        </Text>
        <Button
          colorScheme="orange"
          onClick={handleRequestActivation}
        >
          {t('trialExpired.requestActivationButton')}
        </Button>
      </VStack>
    </Container>
  );
};

export default TrialExpired;