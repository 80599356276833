// FormQuestion.js
import React, { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Image,
  IconButton,
  Textarea,
  Spacer,
  Button,
  useDisclosure,
  Box,
  Modal,
  ModalOverlay,
  HStack,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast
} from '@chakra-ui/react';
import FormQuestionOptions from './FormQuestionOptions';
import { AttachmentIcon, DeleteIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { uploadImageToStorage } from '../../utils/firebase.utils';

const FormQuestion = ({ question, questionIndex, handleFormChange, questionId }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleRemoveQuestion = () => {
    // Add confirmation before removing the question
    if (window.confirm(t('confirm_delete_question'))) {
      handleFormChange('questions', null, questionId);
    }
  };

  const handleImageUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadSubmit = async () => {
    if (selectedFile) {
      try {
        const imageURL = await uploadImageToStorage(selectedFile, `questions/${questionId}`);
        handleFormChange('questions', { ...question, questionImageURL: imageURL }, questionId);
        onClose();
        toast({
          title: t('image_upload_success'),
          description: t('image_uploaded'),
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: t('image_upload_error'),
          description: t('image_upload_failed'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleRemoveImage = () => {
    handleFormChange('questions', { ...question, questionImageURL: null }, questionId);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} my={2}>
    <VStack align="stretch" spacing={4}>
      <FormControl id={`question-name-control-${questionIndex}`}>
        <FormLabel fontSize="md" fontWeight="semibold"> {t('question_label', { index: questionIndex + 1 })}</FormLabel>
        <HStack justifyContent="space-between">
        <Input
          value={question.name}
          onChange={(e) => handleFormChange('questions', { ...question, name: e.target.value }, questionId)}
          placeholder="Enter Question"
          size="md"
        />
       <IconButton
          icon={<AttachmentIcon color={question.questionImageURL ? "green.500" : "gray.500"} />}
          size="sm"
          onClick={onOpen}
          aria-label={t('add_image')}
        />
        {question.questionImageURL && (
          <IconButton
            icon={<DeleteIcon />}
            colorScheme="red"
            size="sm"
            onClick={handleRemoveImage}
            aria-label={t('remove_image')}
          />
        )}
      </HStack>
      </FormControl>

      <FormQuestionOptions
        options={question.options || []}
        question={question}
        handleFormChange={handleFormChange}
        questionIndex={questionId}
      />

        <FormControl id={`question-feedback-control-${questionId}`}>
        <FormLabel>{t('feedback_correct_answer')}</FormLabel>
          <Textarea
            value={question.feedback || ''}
            onChange={(e) => { question['feedback'] = e.target.value;  handleFormChange('questions', question, questionId); }}
            placeholder={t('enter_feedback')}
          />
        </FormControl>

        <Spacer />

        <Button size="sm" colorScheme="red" onClick={handleRemoveQuestion}>{t('remove_question')}</Button>
      {/* Other actions for managing questions can go here */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('upload_image')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {question.questionImageURL || selectedFile ? (
              <Image
                src={
                  selectedFile
                    ? URL.createObjectURL(selectedFile)
                    : question.questionImageURL
                }
                alt={t('uploaded_image')}
                maxH="300px"
                w="100%"
                mt={4}
                objectFit="contain"
              />
            ) : (
              <Input
                type="file"
                onChange={handleImageUpload}
                accept="image/*"
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              variant="ghost"
              onClick={handleUploadSubmit}
              disabled={!selectedFile}
            >
              {t('upload')}
            </Button>
            {question.questionImageURL && (
              <IconButton
                icon={<DeleteIcon />}
                colorScheme="red"
                onClick={handleRemoveImage}
                aria-label={t('remove_image')}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  </Box>
  );
};

export default FormQuestion;