import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Heading, Text, Icon, Button, VStack, Center, Container, useColorModeValue, Spinner
} from '@chakra-ui/react';
import { FaLaptopCode, FaBrain, FaPuzzlePiece, FaRocket } from 'react-icons/fa';

const Feature = ({ title, subtitle, icon }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  return (
    <Box
      p={5}
      backgroundColor="#F3F6FB"
      borderColor=""
      boxShadow="lg"
      rounded="xl"
      w="100%"
    >
      <VStack spacing={3} alignItems="center">
        <Icon as={icon} color="#3E8FFA" w={12} h={12} />
        <Heading size="lg" fontWeight="bold" color="primary">
          {title}
        </Heading>
        <Text fontSize="md" color="text" textAlign="center">{subtitle}</Text>
      </VStack>
    </Box>
  );
};

const StartTrial = ({ onStartTrial }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleStartTrial = async () => {
    setLoading(true);
    try {
      await onStartTrial();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxW="container.xl" py={12}>
      <VStack spacing={10} alignItems="center">
        <VStack alignItems="start" spacing={3}>
          <Heading color="telegram-900" textAlign="center">{t('training.title')}</Heading>
          <Text color="secondary" textAlign="center">{t('training.subtitle')}</Text>
        </VStack>

        <Center w="100%">
          <Button
            bg="#3E8FFA"
            color="white"
            size="lg"
            py={7}
            px={10}
            rounded="xl"
            onClick={handleStartTrial}
            boxShadow="lg"
            fontSize="lg"
            _hover={{ bg: "blue.600" }}
            _active={{ bg: "blue.700" }}
            isDisabled={loading}
          >
            {loading ? <Spinner size="md" /> : t('training.startFreeTrialButton')}
          </Button>
        </Center>

        <Box w="100%">
          <VStack spacing={8} alignItems="stretch" m="0 auto">
            <Feature
              title={t('training.features.internalAssessments.title')}
              subtitle={t('training.features.internalAssessments.subtitle')}
              icon={FaBrain}
            />
            <Feature
              title={t('training.features.aiPotential.title')}
              subtitle={t('training.features.aiPotential.subtitle')}
              icon={FaRocket}
            />
          </VStack>
        </Box>

        <Box w="100%" pt={5}>
          <Text color="secondary" mb={4} textAlign="center">
            {t('training.requestInfo')}
          </Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default StartTrial;