// AdminComponent.js
import React, { useEffect, useState } from 'react';
import {
  Avatar, Box, Flex, Heading, IconButton, Tabs,
  Tab, TabList, useDisclosure, Drawer, DrawerBody,
  Spinner, DrawerOverlay, DrawerContent, Menu,
  MenuButton, Button, MenuList, MenuItem,
  DrawerCloseButton, VStack, Link as ChakraLink, Text,
} from '@chakra-ui/react';
import { HamburgerIcon, QuestionIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase.utils'; 
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { listenToAccountChanges, fetchAccountData } from '../utils/firebase.utils';
import AssessmentValidation from './AssessmentValidation';

const AdminComponent = ({ children, user, accountId }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [iframe, setIframe] = useState(localStorage.getItem("iframe") || false);
  const [status, setStatus] = useState(null);
  const [aData, setAccountData] = useState({});


  useEffect(() => {
    if(accountId){
    const unsubscribe = listenToAccountChanges(accountId, (accountData) => {
      // You can check the data and derive the trial status from 'accountData'
      const trialEnd = accountData?.trialEnd?.toMillis();
      const currentTime = Date.now();
      setAccountData(accountData);

      if (accountData && accountData.paying) {
        setStatus('paying');
      }
      else if (accountData && accountData.onTrial && trialEnd && currentTime < trialEnd) {
        setStatus('onTrial');
      } else if (accountData && trialEnd && currentTime >= trialEnd) {
        setStatus('expired');
      } else {
        setStatus('noTrial');
      }
    });

    // Cleanup listener on unmount
    return () => unsubscribe && unsubscribe();
  }
  }, [accountId]);

  
  useEffect(() => {
    const path = location.pathname;
    if (path === '/') {
      localStorage.setItem('iframe', 'false');
      setIframe(false);
    } else if (path === '/iframe/home') {
      localStorage.setItem('iframe', 'true');
      setIframe(true);
    }
  }, [location]);

  const handleSignOut = () => {
    auth.signOut();
  };

  if(status == 'noTrial' || status == 'expired'){
    return <AssessmentValidation status={status} accountId={accountId} accountData={aData} />
  }

  let content = (
    <Flex direction="column" flex="1" overflowY="auto">
      {!location.pathname.startsWith('/admin/assessments_builder') && !location.pathname.startsWith('/admin/process_generation/') && (
        <Flex align="center" justify="space-between" width="100%">
          <Tabs variant="enclosed" colorScheme="blue"
            index={location.pathname === '/admin/assessments_data' ? 1 : 0}
          >
            <TabList>
              <Tab as={Link} to="/admin/assessments">{t('admin.tabs.assessments')}</Tab>
              <Tab as={Link} to="/admin/assessments_data">{t('admin.tabs.results')}</Tab>
            </TabList>
          </Tabs>
        </Flex>
      )}
      <Box as="main" flex="1" p={4}>
        <AssessmentValidation status={status} accountId={accountId} accountData={aData} />
        {children}
      </Box>
    </Flex>
  );

  if (!user) {
    content = <Spinner />;
  }

  if (localStorage.getItem("iframe") === 'true') {
    return content;
  }

  return (
    <Flex minHeight="100vh" direction="column" justify="space-between">
      {/* Header */}
      <Flex as="header" align="center" justifyContent="space-between" wrap="wrap" p={4} bg="blue.600" color="white">
        <Flex align="center">
          <IconButton icon={<HamburgerIcon />} onClick={onOpen} variant="" aria-label={t('admin.menu')} size="sm" />
          <Heading as="h1" size="lg" ml={3}>
            EduScope
          </Heading>
        </Flex>
        <Menu>
          <MenuButton as={Button} variant="ghost" mr={2}>
            <Avatar size="sm" src={user?.photoURL} />
          </MenuButton>
          <MenuList bg="blue.600">
            <MenuItem bg="blue.600" onClick={handleSignOut}>{t('admin.signOut')}</MenuItem>
          </MenuList>
        </Menu>
      </Flex>

      {/* Drawer for mobile nav */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <VStack mt={10} spacing={4}>
              <ChakraLink as={Link} to="/admin/assessments" onClick={onClose}>
                {t('admin.drawer.assessments')}
              </ChakraLink>
              <ChakraLink as={Link} to="/admin/assessments_data" onClick={onClose}>
                {t('admin.drawer.assessmentsData')}
              </ChakraLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Main Content Area */}
      {content}
      
      {/* Footer */}
      <Box as="footer" bg="blue.600" color="white" py={2}>
        <Flex align="center" justify="space-between" p={4}>
          <Text>&copy; {new Date().getFullYear()} EduScope. {t('admin.footer.rights')}</Text>
          <Text>{t('admin.footer.poweredBy')}</Text>
        </Flex>
      </Box>

    </Flex>
  );
};

export default AdminComponent;