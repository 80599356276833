import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Button,
  Spinner,
  Flex,
  Spacer,
  Center,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getAssessments, deleteAssessment } from '../utils/firebase.utils';
import { useNavigate } from 'react-router-dom';
import { createBlankAssessment } from '../utils/firebase.utils';
import { ChevronDownIcon, AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { FaMagic } from 'react-icons/fa';

const Assessments = ({ accountId, user }) => {
  const [assessments, setAssessments] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState(null);
  const cancelRef = useRef();
  const pageSize = 10; 
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateWithIframePrefix = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (!accountId) {
      return;
    }
    const fetchAssessments = async () => {
      setLoading(true);
      try {
        const { assessments, lastVisible } = await getAssessments(accountId, null, null, pageSize);
        setAssessments(assessments);
        setLastVisible(lastVisible);
        setHasMore(assessments.length === pageSize);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssessments();
  }, [accountId]);

  const loadMore = async () => {
    if (!lastVisible) return;

    setLoading(true);
    try {
      const { assessments: newAssessments, lastVisible: newLastVisible } = await getAssessments(accountId, null, lastVisible, pageSize);
      setAssessments([...assessments, ...newAssessments]);
      setLastVisible(newLastVisible);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const navigateToAssessmentBuilder = (assessmentId) => {
    navigateWithIframePrefix(`/admin/assessments_builder/${assessmentId}`);
  };

  const navigateToAssessmentResults = (assessmentId) => {
    navigateWithIframePrefix(`/admin/assessments_builder/${assessmentId}?show_results=true`);
  };

  const navigateToNewAssessment = () => {
    navigateWithIframePrefix('/admin/new_assessment');
  };

  const createAndNavigateToBlankAssessment = async () => {
    const assessmentId = await createBlankAssessment(accountId, user);
    navigateToAssessmentBuilder(assessmentId);
  };

  const handleDeleteAssessment = () => {
    if (assessmentToDelete) {
      deleteAssessment(accountId, assessmentToDelete);
      setAssessments(assessments.filter(assessment => assessment.id !== assessmentToDelete));
      setAssessmentToDelete(null);
    }
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = (assessmentId) => {
    setAssessmentToDelete(assessmentId);
    console.log('deleting assessment', assessmentId);
    setIsDeleteDialogOpen(true);
  };

  if (!accountId) {
    return <Spinner />;
  }

  return (
    <VStack spacing={4} align="stretch" p={5}>
      <Flex justify="flex-end" mb={4}>
        <Box>
          <Button colorScheme="blue" leftIcon={<FaMagic />} mr={2} onClick={navigateToNewAssessment}>{t('Create with AI')}</Button>
          <Button leftIcon={<AddIcon />} onClick={createAndNavigateToBlankAssessment}>{t('Blank Assessment')}</Button>
        </Box>
      </Flex>
      {loading ? <Spinner /> : 
      (assessments.length > 0 ? (
        assessments.map((assessment) => ( !assessment.isDeleted && (
          <Flex key={assessment.id} p={5} shadow="sm" borderWidth="1px" align="center">
            <VStack align="start" onClick={() => navigateToAssessmentBuilder(assessment.id)}>
              <Text fontSize="xl" cursor="pointer" color="blue.500" _hover={{ textDecoration: 'underline' }}>{assessment.title}</Text>
              <Text>{assessment.description}</Text>
            </VStack>
            <Spacer />
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<ChevronDownIcon />}
                variant="outline"
                aria-label="Options"
              />
              <MenuList>
                <MenuItem icon={<EditIcon />} onClick={() => navigateToAssessmentBuilder(assessment.id)}>
                  {t('Edit')}
                </MenuItem>
             {/*    <MenuItem icon={<ExternalLinkIcon />} onClick={() => navigateToAssessmentResults(assessment.id)}>
                  {t('Show Results')}
                </MenuItem> */}
                <MenuItem icon={<DeleteIcon />} onClick={() => openDeleteDialog(assessment.id)}>
                  {t('delete')}
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>)
        ))
      ) : (
        <Center flex="1">
          <Text fontSize="xl">{t('no_assessments')}</Text>
        </Center>
      ))}
      {assessments.length > 0 && hasMore && <Button onClick={loadMore} isLoading={loading}>{t('Load More')}</Button>}
      
      <AlertDialog
        isOpen={isDeleteDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('delete_assessment')}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t('delete_assessment_confirmation')}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleteDialogOpen(false)}>
                {t('cancel')}
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAssessment} ml={3}>
                {t('delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default Assessments;