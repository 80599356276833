import React, { useEffect, useState } from 'react';
import {
  Box, Button, Image, FormControl, FormLabel, Radio, RadioGroup, Stack, Heading, Text, AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, VStack, Progress, useToast, StackDivider, Input, Divider, Grid, GridItem,
} from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CheckIcon } from '@chakra-ui/icons';
import { checkEmailInInvitations, getForm, getSubmissionData, initFormSubmission, updateFormResponse, startForm as startFormUtil, markSubmissionAsFinished, requestAdminApproval } from '../utils/firebase.utils';
import QuestionResponseFeedback from './QuestionResponseFeedback';
import { useTranslation } from 'react-i18next';

function Assessment({  user }) {
  const { formId, accountId } = useParams();
  const [form, setForm] = useState(null);
  const [responses, setResponses] = useState({});
  const [submissionRef, setSubmissionRef] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);
  const [timer, setTimer] = useState(null);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [searchParams] = useSearchParams();
  const cancelRef = React.useRef();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const isPreview = searchParams.get('preview') === 'true';

  const [rutInput, setRutInput] = useState('');
  const [cedulaInput, setCedulaInput] = useState('');
  const [customIdentifierInput, setCustomIdentifierInput] = useState('');
  const [errors, setErrors] = useState({});
  const [hasUserIdentifier, setHasUserIdentifier] = useState(false);
  const submissionId = searchParams.get('submissionId');


  const validateChileanRUT = rut => {
    // Dummy validation function, replace with real validation logic
    return /^0*(\d{1,3}(\.?\d{3})*)\-?([\dkK])$/.test(rut);
  };

  const handleStartAssessment = async () => {
    // Clear previous errors
    setErrors({});
    console.log('start assessment', email);
    if(!submissionId){
      if (!email.trim()) {
        setErrors({ email: t('email_required') });
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        console.log('invalid email');
        setErrors({ email: t('invalid_email_format') });
        return;
      }
    }

    if (form?.privateAssessment && !hasUserIdentifier) {

      try {
        const invitation = await checkEmailInInvitations(accountId, formId, email);
        if (!invitation) {
          await requestAdminApproval(email, accountId, formId);
          toast({
            title: t('request_sent_title'),
            description: t('request_sent_description'),
            status: 'info',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      } catch (error) {
        console.error("Error checking email or sending request:", error);
        return;
      }
    }

    // Check for logged-in user
    if (form?.requireAccount && !user) {
      // Trigger the logic to show the Login component or prompt the user to log in
      // Depending on your application structure, you may redirect, open a modal, etc.
      console.error("Login required");
      return;
    }

    let userIdent, userIdentInfo;

    // Check for identifier requirements
    if (form.askForIdentifier) {
      if (form.country === 'CL' || (!form.authenticatorType || form.authenticatorType === 'country')) {
        if (!validateChileanRUT(rutInput)) {
          setErrors(errors => ({ ...errors, rut: "Invalid RUT" }));
          return;
        }
        if (!cedulaInput) {
          setErrors(errors => ({ ...errors, cedula: "N° de Serie is required" }));
          return;
        }
        userIdent = rutInput.trim();
        userIdentInfo = cedulaInput.trim();

      } else if (form.authenticatorType === 'custom') {
        if (!customIdentifierInput) {
          setErrors(errors => ({ ...errors, customIdentifier: `${form.customIdentifier} is required` }));
          return;
        }
        userIdent = customIdentifierInput.trim();
        userIdentInfo = null; // No secondary info for custom
      }
    }

    // If no errors, proceed to start the form
    const startResult = await startFormUtil({ accountId: accountId, formId: formId, assessmentName: (form || {}).title, userIdentifier: userIdent, email: email,  userIdentifierInfo: userIdentInfo, isPreview: isPreview, sId: submissionId});

    
    if (startResult && startResult.hasStarted) {
      setIsConfirmOpen(false); // Close the confirmation modal
      setHasStarted(true); 
      const startTime = new Date(startResult.startTime.seconds * 1000);
      setTimer(parseInt(form.time) * 60); // Initialize the timer with the total time
      calculateRemainingTime(startTime, parseInt(form.time));
      console.log("Form has started successfully.");
      // You can set any state here as needed to indicate the form has started
    } else {
      console.error("Error starting the form");
      // Handle the case where the form could not be started
    }
  };

// Fetch form details and initialize submission reference
useEffect(() => {
  const fetchData = async () => {
    try {
      const formDetails = await getForm(accountId, formId);
      setForm(formDetails);
      setTimer(formDetails.time || 60);
      let submissionData;

        // Initialize new submission if submissionId is not provided
        const submissionRef = await initFormSubmission(accountId, formId, isPreview, submissionId);
        setSubmissionRef(submissionRef);
        // Fetch submission data directly using submissionId
        submissionData = await getSubmissionData(submissionRef, accountId, submissionId);
        if (submissionData.userIdentifier) {
          setHasUserIdentifier(true);
        }
        setResponses(submissionData.responses || {});



      if (submissionData && submissionData.hasStarted && submissionData.startTime) {
        const startTime = submissionData.startTime.toDate();
        setIsFinished(submissionData.isFinished || false);
        calculateRemainingTime(startTime, formDetails.time || 60);
        setHasStarted(true);
      }

    } catch (error) {
      console.error("Error fetching form or initializing submission:", error);
    }
  };

  fetchData();
}, [accountId, formId, submissionId]);

 // Render logic including identifier inputs
 const renderIdentifierInput = () => {

  return (
    <>
    {(form?.privateAssessment ||  !hasUserIdentifier ) && (
          <>
            <label>Email:</label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            {errors.email && <Text color="red.500">{errors.email}</Text>}
          </>
        )}
  {form?.askForIdentifier && form.authenticatorType === 'country' && ( !form.country  || form.country === 'CL') && (
      <>
        <label>RUT:</label>
        <Input type="text" value={rutInput} onChange={(e) => setRutInput(e.target.value)} placeholder="Ingrese el RUT" />
        {errors.rut && <p style={{ color: 'red' }}>{errors.rut}</p>}

        <label>N° de Serie de la Cédula:</label>
        <Input type="text" value={cedulaInput} onChange={(e) => setCedulaInput(e.target.value)} placeholder="N° de Serie de la Cédula" />
        {errors.cedula && <p style={{ color: 'red' }}>{errors.cedula}</p>}
        <Image src="/numserie.png" pt={5}  w={{ base: '80%' }} alignSelf="center"  alt="N° de Serie de la Cédula" />
      </>
    )}
 {form?.askForIdentifier && form.authenticatorType === 'country' && (  form.country == 'MX') && (
    <>
    <label>CURP:</label>
    <Input type="text" value={rutInput} onChange={(e) => setRutInput(e.target.value)} placeholder="Ingrese el CURP" />
    {errors.rut && <p style={{ color: 'red' }}>{errors.rut}</p>}
  </>
  )}
  {form?.askForIdentifier && form.authenticatorType === 'custom' && (
 
      <>
        <label>{form.customIdentifier || "Custom Identifier"}:</label>
        <Input type="text" value={customIdentifierInput} onChange={(e) => setCustomIdentifierInput(e.target.value)} placeholder={form.customIdentifier} />
        {errors.customIdentifier && <p style={{ color: 'red' }}>{errors.customIdentifier}</p>}
      </>
    )
 }
 </>);
};


const calculateRemainingTime = (startTimeParam, totalTimeInMinutes) => {
  // Parse the startTimeParam to ensure it is a Date object
  const startTime = startTimeParam instanceof Date ? startTimeParam : new Date(startTimeParam);

  // Perform a basic check to make sure startTime is valid
  if (isNaN(startTime.getTime()) || totalTimeInMinutes == null) {
    console.error('Invalid start time or total time.');
    setTimer(null);
    return;
  }

  const totalTimeInSeconds = totalTimeInMinutes * 60;

  // Calculate the initial remaining time
  const updateTimer = () => {
    const currentTime = new Date();
    const elapsedTime = (currentTime.getTime() - startTime.getTime()) / 1000;
    const remainingTime = totalTimeInSeconds - elapsedTime;

    if (remainingTime <= 0) {
      clearInterval(timerIntervalId);
      setTimer(0);
      // Additional logic for expired timer
    } else {
      setTimer(remainingTime);
    }
  };

  updateTimer(); // Update the timer immediately to avoid displaying NaN

  const intervalId = setInterval(updateTimer, 1000);
  setTimerIntervalId(prevIntervalId => {
    // Clear any existing timer interval
    if (prevIntervalId) {
      clearInterval(prevIntervalId);
    }
    // Return the new interval ID
    return intervalId;
  });
};

useEffect(() => {
  return () => {
    // Clean up the interval when necessary
    if (timerIntervalId) {
      clearInterval(timerIntervalId);
    }
  };
}, [timerIntervalId]);



  const handleSubmit = async (event) => {
    event.preventDefault();
    await confirmAndSubmit();
  };

// Handle updating response state and Firestore on question change
const handleQuestionChange = async (questionId, selectedOptionId) => {
  const selectedOption = form.questions[questionId].options[selectedOptionId];
  const newResponse = {
    id: selectedOptionId,
    name: selectedOption.name,
    formId: formId,
    accountId: accountId
    };

  setResponses(prev => ({ ...prev, [questionId]: newResponse }));
  if (submissionRef) {
    await updateFormResponse(submissionRef, questionId, newResponse);
  }
};

 const toast = useToast();


const confirmAndSubmit = async () => {
  // Implementation to confirm submission (e.g., using AlertDialog)
  // Set assessment as finished and mark it on Firestore
  if (submissionRef) {
    await markSubmissionAsFinished(accountId, formId, submissionRef);

    // Update isFinished state
    setIsFinished(true);
  // This is a new function that you need to implement in `firebase.utils.js`

  // Provide feedback to the user that the assessment is submitted and cannot be modified
  //TODO: fix this toast i18n
  toast({
    title: t('assessment_submitted'),
    description: t('feedback_and_responses'),
    status: 'success',
    duration: 5000,
    isClosable: true
  });
}
};

const getPdfIframe = (pdfUrl) => {
  return (
    <iframe
      src={pdfUrl}
      type="application/pdf"
      width="100%"
      height="600px"
      style={{minHeight: '600px', border: 'none'}}
     />
  );
};
const getVideoIframe = (url) => {
  // Detects YouTube videos and uses an appropriate embed URL
  const youtubeRegexp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi;
  const youtubeMatch = youtubeRegexp.exec(url);
  if (youtubeMatch && youtubeMatch[1]) {
    const videoID = youtubeMatch[1];
    return `https://www.youtube.com/embed/${videoID}`;
  }
  // For non-YouTube videos, return the URL as it is (assuming it is IFRAME-ready).
  // You might need to adjust this depending on the video services you want to support.
  return url;
};


if(!accountId){
  return <Text>Loading...</Text>
}


const OptionItem = ({ option, optionId, isDisabled, isCorrectAnswer, bgColor, shouldDisplayFeedback }) => (
  <VStack spacing={3}>
    {option.imageURL && (
      <Image src={option.imageURL} alt={`Option ${optionId}`} boxSize="100px" objectFit="contain" />
    )}
    <Radio value={optionId} isDisabled={isDisabled} bg={bgColor}>
      {option.name}
      {isCorrectAnswer && isDisabled && shouldDisplayFeedback && <CheckIcon ml="2" color="green.500" />}
    </Radio>
  </VStack>
);




  return (
    <>
    <AlertDialog isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">{t('start_assessment')}</AlertDialogHeader>
            <AlertDialogBody>
              {t('are_you_ready')}
              {/* Render additional identifier inputs */}
              <Stack spacing={2} w="full" mt={5}>
              {renderIdentifierInput()}
              </Stack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>{t('cancel')}</Button>
              {/* We pass handleStartAssessment directly, replacing previous startForm */}
              <Button colorScheme="blue" onClick={handleStartAssessment} ml={3}>{t('start')}</Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <VStack as="form" onSubmit={handleSubmit} p={5} shadow="md" borderWidth="1px" spacing={8} alignItems="flex-start" divider={<StackDivider borderColor="gray.200" />}>

      {form ? (
        <>
          <Heading as="h1" mb={5} >{form.title}</Heading>
          
          <VStack alignItems="flex-start" spacing={4} mb={5}>
            <Text fontSize="lg">{form.description}</Text>
            <Text fontSize="md" dangerouslySetInnerHTML={{ __html: form.instructions }} /> {/* Consider sanitizing HTML content */}
        {/*     {hasStarted && (
            <TrackingActivity
                hasStarted={hasStarted}
                submissionRef={submissionRef}
                accountId={accountId}
                formId={formId}
                initialData={responsesData.trackingData || {}}
              />
            )} */}
        {form.videoLink && !hasStarted && (
        <Box className="video-responsive" my={5}>
          <iframe
            width="560"
            height="315"
            src={getVideoIframe(form.videoLink)}
            allowFullScreen
          ></iframe>
        </Box>
      )}
      {form.pdfDocument  && !hasStarted && (
        <Box className="pdf-responsive" my={5}>
          {getPdfIframe(form.pdfDocument)}
        </Box>
      )}
          </VStack>
          {hasStarted ? (
            <>
              <Stack spacing={6} w="full">
              { !isFinished &&
              <>
              <Progress colorScheme="blue" size="sm" value={(1 - (timer / (form.time * 60))) * 100} w="full" />
              { !isNaN(timer) && (
              <Text>
              {t('time_remaining')}: {Math.floor(timer / 60)}:{('0' + Math.floor(timer % 60)).slice(-2)}
              </Text>)}
               </>}
                {Object.keys(form.questions).map(question_id => {
                  const question = form.questions[question_id];
                  const response = responses[question_id];
                  const isAnswered = responses[question_id] !== undefined;
                  const userResponse = responses[question_id] || {};
                  const numImages = Object.values(question.options).filter(opt => opt.imageURL).length;
                  const numCols = numImages > 0 ? Math.ceil(12 / numImages) : 12;

                
                  // Determine the option ID with the highest value as the correct answer
                  const correctOptionId = Object.keys(question.options).reduce((maxId, currentId) => {
                    return question.options[maxId].value < question.options[currentId].value ? currentId : maxId;
                  }, Object.keys(question.options)[0]);
                
                  // Determine if the user's response ID matches the correct option ID
                  const isUserCorrect = userResponse && userResponse.id === correctOptionId;
                   // Check if we should display feedback (if the timer has finished)
                  const shouldDisplayFeedback = ((timer != null && timer <= 0) || isFinished) && form.showFeedback;
                  return (
                    <VStack flexDir="column" alignItems="flex-start" key={question_id} ml={10}>
                    <FormControl key={question_id} isRequired isDisabled={(timer != null && timer <= 0) || isFinished}>
                    <VStack alignItems="flex-start" spacing={1} pb={10}>
                      <FormLabel fontWeight="bold">{form.questions[question_id].name}</FormLabel>
                      {form.questions[question_id].questionImageURL && (
                        <Image src={form.questions[question_id].questionImageURL} boxSize="250px" objectFit="contain" />
                      )}
                    </VStack>
                      <RadioGroup onChange={value => handleQuestionChange(question_id, value)}
                                  name={`question_${question_id}`}
                                  value={(responses[question_id] || {}).id || ''}>
                        <VStack alignItems="flex-start">
                          {numImages > 0 ? (
                            <Grid templateColumns={`repeat(${numCols}, 1fr)`} gap={6}>
                              {Object.entries(question.options).map(([optionId, option]) => {
                                const isCorrectAnswer = optionId === correctOptionId;
                                let bgColor = shouldDisplayFeedback && isCorrectAnswer ? 'green.100' : undefined;
                                return (
                                  <GridItem key={optionId}>
                                    <OptionItem
                                      option={option}
                                      optionId={optionId}
                                      isDisabled={(timer != null && timer <= 0) || isFinished}
                                      isCorrectAnswer={isCorrectAnswer}
                                      bgColor={bgColor}
                                      shouldDisplayFeedback={shouldDisplayFeedback}
                                    />
                                  </GridItem>
                                );
                              })}
                            </Grid>
                          ) : (
                            Object.entries(question.options).map(([optionId, option]) => {
                              const isCorrectAnswer = optionId === correctOptionId;
                              let bgColor = shouldDisplayFeedback && isCorrectAnswer ? 'green.100' : undefined;
                              return <OptionItem
                                key={optionId}
                                option={option}
                                optionId={optionId}
                                isDisabled={(timer != null && timer <= 0) || isFinished}
                                isCorrectAnswer={isCorrectAnswer}
                                bgColor={bgColor}
                              />;
                            })
                          )}
                          <Divider mt={5} />
                        </VStack>
                      </RadioGroup>
                      {isAnswered && !isUserCorrect && form.showFeedback && ((timer != null && timer <= 0) || isFinished) && (
                        <QuestionResponseFeedback
                          question={question}
                          response={userResponse?.name || ''}
                          isCorrect={isUserCorrect}
                        />
                      )}
                    </FormControl>
                  </VStack>);
                })}
              </Stack>
                { !isFinished && 
              <Button colorScheme="blue" mt={10} type="submit" size="lg">{t('submit_assessment')}</Button> }
            </>
          ) : (
            <Button colorScheme="blue" mt={5} onClick={() => setIsConfirmOpen(true)} size="lg">{t('start_assessment')}</Button>
          )}

        </>
      ) : (
        <Text fontSize="lg" color="gray.500">{t('loading_form')}</Text>
      )}

    </VStack>
    </>
  );

}


export default Assessment;
