// ShareFormPopup.js
import React, { useRef, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Switch,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useClipboard,
  Input,
  InputRightElement,
  useToast,
  IconButton,
  InputGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import InviteUsersForm from './InviteUsersForm';
import { QRCodeCanvas } from 'qrcode.react';
import { CopyIcon } from '@chakra-ui/icons';

const ShareFormPopup = ({ isOpen, onClose, formDetails, handleFormChange, accountId }) => {
  const linkInputRef = useRef(null);
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(linkInputRef.current?.value);
  const shareLink = `${window.location.origin}/assessment/${accountId}/${formDetails.id}`;
  const [isUpdating, setIsUpdating] = useState(false);

  const togglePrivateAssessment = () => {
    const newValue = !formDetails.privateAssessment;
    handleFormChange('privateAssessment', newValue, null, true); // Auto-save changes
  };


  // Placeholder content for email sharing and link sharing tabs
  // TODO: Add actual implementation for sharing via email and link with QR code
  const emailSharingContent = <><InviteUsersForm formId={formDetails.id} accountId={accountId} /></>
  const LinkSharingTab = formDetails.privateAssessment ? (
    <Text color="red.500" mt={4}>{t('assessment_make_public_warning')}</Text>
  ) : (
    <>
      <InputGroup size="md" mt={4}>
        <Input pr="4.5rem" ref={linkInputRef} value={shareLink} isReadOnly />
        <InputRightElement width="4.5rem">
          <IconButton
            h="1.75rem"
            size="sm"
            variant="ghost"
            aria-label={t('copy_link')}
            icon={<CopyIcon />}
            onClick={onCopy}
            isRound
          />
        </InputRightElement>
      </InputGroup>
      {hasCopied ? (
        <Text mt={2} color="green.500">{t('link_copied_confirmation')}</Text>
      ) : null}
      <FormControl mt={4}>
        <FormLabel>{t('assessment_qr_code')}</FormLabel>
        <QRCodeCanvas value={shareLink} size={128} level="L" />
      </FormControl>
    </>
  );


  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('share_assessment')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>{t('share_via_email')}</Tab>
              <Tab>{t('share_via_link')}</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>{emailSharingContent}</TabPanel>
              <TabPanel>
                {/* Render the private assessment switch only in the Link Sharing tab */}
                <FormControl display="flex" flexDirection="column" alignItems="start" mb={4}>
                  <FormLabel htmlFor="private-assessment" mb="0">
                    {t('private_assessment')}
                  </FormLabel>
                  <Switch
                    id="private-assessment"
                    isChecked={formDetails.privateAssessment || false}
                    onChange={togglePrivateAssessment}
                  />
                  <Text fontSize="sm" mt={2}>
                    {t('private_assessment_subtitle')}
                  </Text>
                </FormControl>
                {LinkSharingTab}
              </TabPanel>
            </TabPanels>
          </Tabs>

        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>{t('close')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareFormPopup;